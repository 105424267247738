import './vue_overrides';
import { addAppealModalPopupListener } from 'jh/appeal';

function deferredInitialization() {
  const $html = document.querySelector('html');
  setTimeout(() => $html.classList.add('jh-page-wrapper'), 1000);
}

requestIdleCallback(deferredInitialization);

addAppealModalPopupListener();
